<template>
  <div>
    <WebsiteNavigation>
      <template slot="content">
        <div class="banner">
          <img class="bg" src="../../assets/img/faq.png">
          <div class="text">
            <span>Frequently Asked Questions</span>
          </div>
        </div>
        <div class="main">
          <div class="content">
            <div v-for="item in content" :key="item.id">
              <div :class="['el-collapse-item__header',item.isActive ? 'is-active' : '']" @click="handleClick(item.id)">{{ item.title }}<i style="color:#7B60C4" :class="['el-collapse-item__arrow',item.isActive ? 'el-icon-minus' : 'el-icon-plus']" /></div>
              <CollapseTransition>
                <div v-show="item.isActive" class="el-collapse-item__wrap">
                  <div class="el-collapse-item__content">
                    <div v-if="Array.isArray(item.detail)">
                      <div v-for="(val,i) in item.detail" :key="i" class="item">{{ val }}</div>
                    </div>
                    <div v-else>{{ item.detail }}</div>
                  </div>
                </div>
              </CollapseTransition>
            </div>
          </div>
        </div>
      </template>
    </WebsiteNavigation>
  </div>
</template>

<script>
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import WebsiteNavigation from '@/components/layout/WebsiteNavigation.vue'

export default {
  components: {
    CollapseTransition,
    WebsiteNavigation
  },
  data() {
    return {
      content: [
        { id: 0, isActive: false, title: 'What is KakaClo?', detail: 'KakaClo is an apparel platform with its own factory and integrated supply chain, provides the most professional dropshipping and custom production service.' },
        { id: 1, isActive: false, title: 'How to join KakaClo Affiliate Program?', detail: 'Easy! All you need to do is simply register an affiliate account and then you will get a unique affiliate link, users who register through your affiliate link will be counted as your referrals and generate passive income for you once they start selling.' },
        { id: 2, isActive: false, title: 'Does it cost me anything to become an affiliate?', detail: 'The program is absolutely free to join,we do not charge anything from you.' },
        { id: 3, isActive: false, title: 'How does the commission work? How and when to receive?', detail: [
          `1. Commission = (Amount of Total Orders - Amount of Disputed Orders) x 2% Total Orders includes both dropshipping orders and wholesale orders. Shipping fee will also be included. Disputes happen when the payment is not received by KakaClo when a deal closes.`,
          `2. We track orders from a user who comes from your link for an entire year, and pay the commission accordingly. The day that the user places his first order will be considered as day 1, meaning that we don’t start the countdown until an order has been placed by this specific user.`,
          `3. You may start withdrawing your commission once you convert at least 10 leads, meaning 10 customers who have placed orders from KakaClo.`,
          `4. You may withdraw daily. Your commission will be updated at 0:00 (UTC+8) every day including the addition from new orders and subtraction from previous disputed orders.`,
          `5. You will receive your commission by PayPal, Payoneer, or KakaClo Wallet in 1-3 working days after you click the Withdraw button.`
        ] },
        { id: 4, isActive: false, title: 'How to monitor my leads and commission?', detail: 'Once you have your affiliate account, you will be able to monitor your leads and commission on the dashboard in the system.' },
        { id: 5, isActive: false, title: 'Are there any limitations?', detail: [
          `1. We will not be able to track the registrations which don't go through your affiliate link.`,
          `2. The purpose of KakaClo Affiliate Program is to generate more effective users. Therefore you may not refer your affiliate link to your own Kaka account in order to get a 2% discount, if we detect any related anomalies, we might freeze your account.`
        ] },
        { id: 6, isActive: false, title: 'Who processes orders and handles customer service?', detail: 'We do everything. We host the products, handle downloads and customer queries. All you have to do is just place links and refer visitors to our website.' }
      ]
    }
  },
  methods: {
    handleClick(index) {
      this.content[index].isActive = !this.content[index].isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  .bg {
    position: absolute;
    display: block;
    width: 100%;
    min-width: 1400px;
    height: 500px;
  }
  .text {
    position: relative;
    width: 1400px;
    height: 500px;
    margin: 0 auto;
    z-index: 9;
    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: #fff;
      height: 66px;
      opacity: 0.88;
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
      letter-spacing: 0.8px;
    }
  }
}

.main {
  background: #fff;
  .content {
    width: 1400px;
    margin: 0 auto 142px auto;
    .item {
      margin-top: 6px;
    }
  }
  /deep/.el-collapse-item__header {
    height: 70px;
    font-size: 18px;
    font-weight: 500;
    color: #121e26;
  }
  /deep/.el-collapse-item__content {
    font-size: 14px;
    font-weight: 500;
    color: #606060;
  }
}
</style>
